<template>
  <div class="main-quiz">
    <main v-if="formData && stat.level" class="content__content pRel" key="t1">
      <div class="content__wrapper heightAll">
        <!-- div v-if="stat.level" class="content__progressBox">
          <div class="pRel">
            <div class="medium text_md progress__text layout pAbs">{{ stat.level }}/{{ stat.levels }}</div>
            <svg viewBox="0 0 32 32" class="progress__container">
              <circle class="progress__circleEmpty" r="16" cx="16" cy="16"></circle>
              <circle class="progress__circleFull" r="16" cx="16" cy="16" :stroke-dasharray="progressBar(stat.progress)" ></circle>
            </svg>
          </div>
        </div -->
        
        <div class="content__form">
          <div class="content__formWrapper">
            <div class="title-step-progress">
              {{ stat.level }} из {{ stat.levels }}
            </div>
            <div v-if="formData.heading" class="form__title title__form bold">{{ formData.heading }}</div>
            
            <div class="content__formContainer">
              <template v-if="formData.fields">
                <dynamic-form/>
              </template>
              <template v-else-if="formData.component">
                <component :is="formData.component"/>
              </template>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div v-else-if="formData" class="listing__layoutAll growOn pRel" key="t2">
      <component :is="formData.component"/>
    </div>
    <div class="main-quiz-bg-wrapper">
      <div class="main-quiz-bg" v-for="(item, index) in stat.levels" :class="['bg-step-'+ index, {'active' : stat.level == (index + 1)}]"></div>
    </div>
  </div>
</template>

<script>
import Quiz from '../quiz/App'
import DynamicForm from '@/modules/quiz-landing/components/DynamicFormComponent'
import PriceForm from '@/modules/quiz-landing/components/PriceFormComponent'
import PriceFormRadio from '@/modules/quiz-landing/components/PriceFormRadioComponent'
import SendForm from '@/modules/quiz-landing/components/SendFormComponent'
import FinishingFormComponent from '@/modules/quiz-landing/components/FinishingFormComponent'

export default {
  mixins: [Quiz],
  components: {
    'dynamic-form': DynamicForm,
    'price-form': PriceForm,
    'price-form-radio': PriceFormRadio,
    'send-form': SendForm,
    'finishing-form': FinishingFormComponent
  },
  methods: {
    progressBar (val) {
      return val + ' 100';
    }
  }
}
</script>
