<template>
    <div>
        <div v-if="result">
            <h4 v-html="renderHeading" class="fs_22 lh_28 fw_m mb_20"></h4>
            <div v-if="!success" class="fs_12 lh_16 def_gray tt_u mb_10">* Поля обязательные к заполнению</div>
            <form v-if="!success" novalidate @submit.prevent="checkForm">
                <div class="mb_10">
                    <div class="row-flex d_f f_w_w">
                        <div v-if="!isMobile" class="col-xs-12 mb_10 pos_rel" :class="{ 'has-error': errors.has('username') }">
                            <input
                                type="text"
                                v-model="username"
                                name="username"
                                v-validate="'min:2|max:50'"
                                class="poll-border b-castom-field-item bl-theme-interactive"
                                placeholder="Как вас зовут?"
                            />
                            <span v-if="errors.has('username')" class="help-block">
                                Имя должно содержать от 2 до 50 символов
                            </span>
                        </div>
                        <div class="col-xs-12 mb_10 pos_rel" :class="{ 'has-error': errors.has('phone') }">
                            <input
                                v-model="phone"
                                v-phone
                                name="phone"
                                maxlength="18"
                                v-validate="{ required: true, regex: phoneRegex }"
                                class="poll-border b-castom-field-item bl-theme-interactive"
                                placeholder="Номер телефона *"
                                @keyup="eventLastLetter"
                            />
                            <span v-if="errors.has('phone')" class="help-block">
                                Необходимо заполнить поле «Телефон» в формате +7 (xxx) xxx-xx-xx
                            </span>
                        </div>
                        <div v-if="!isMobile" class="col-xs-12 mb_10 pos_rel" :class="{ 'has-error': errors.has('email') }">
                            <input
                                type="email"
                                name="email"
                                v-model="email"
                                v-validate="'required|email'"
                                class="poll-border b-castom-field-item bl-theme-interactive"
                                placeholder="Электронная почта *"
                                @blur="eventBlurEmail"
                            />
                            <span v-if="errors.has('email')" class="help-block">
                                Введите корректно email
                            </span>
                        </div>
                    </div>
                </div>

                <div v-if="error" class="alert alert-danger">{{ error }}</div>

                <div class="d_f a_i_c poll-btn-wrap">
                    <button
                        :disabled="loader"
                        @click="$store.commit('prev')"
                        class="p_15 bgr_f5f5f5 name_color d_i_f mr_10 br_2 btn-svg-item-2"
                    >
                        <div class="">
                            <svg fill="#212121" class="w_24">
                                <use xlink:href="#arrowback-24"/>
                            </svg>
                        </div>
                        <div class="ml_15 fs_16 lh_24 fw_m hidden-xs pr_5">Назад</div>
                    </button>

                    <button
                        :disabled="loader"
                        type="submit"
                        class="p_15 bgr_f5f5f5 name_color btn-svg-item br_2 pl_20 d_f "
                    >
                        <div class="fs_16 lh_24 fw_m ">
                            Отправить
                        </div>
                        <div class=" ml_15 ">
                            <svg fill="currentColor" class="w_24">
                                <use xlink:href="#arrowforward-24"/>
                            </svg>
                        </div>
                    </button>
                    <div class="poll-send-text name_color ">Нажимая на кнопку, Вы даете согласие на обработку своих <a class="ws_nw" href="/soglashenie" target="_blank">персональных данных</a>.</div>
                </div>
            </form>
            <div class="d_f" v-else>
                <button
                    style="background-color: #f5f5f5;"
                    class="p_15 bgr_f5f5f5 name_color btn-svg-item br_2 pl_20 d_f btn-svg-item-2"
                    @click="goHome()"
                >
                    <div class="fs_16 lh_24 fw_m ">Подобрать ещё раз</div>
                    <div class=" ml_15 ">
                        <svg fill="#212121" class="w_24">
                            <use xlink:href="#refresh-24"/>
                        </svg>
                    </div>
                </button>
            </div>
        </div>
        <div v-else>
            loading...
        </div>
    </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { TextHelper } from '../../../helpers/TextHelper';
  //import SendPhoneMixin from '../../../mixins/MCRegisterPhoneMixin'

  const phoneModel = window.sendPhone();

  export default {
    name: 'send-form',
    //mixins: [ SendPhoneMixin ],
    computed: {
      ...mapState({
        form: 'form',
        result: 'result',
        loader: 'loaderSend',
        success: 'successSend',
        error: 'error',
        isMobile: 'isMobile'
      }),
      username: {
        get() {
          return this.form.username || '';
        },
        set(value) {
          this.$store.commit('setValue', {
            name: 'username',
            value
          });
        }
      },
      email: {
        get() {
          return this.form.email || '';
        },
        set(value) {
          this.$store.commit('setValue', {
            name: 'email',
            value
          });
        }
      },
      phone: {
        get() {
          return this.form.phone || '';
        },
        set(value) {
          this.$store.commit('setValue', {
            name: 'phone',
            value
          });
        }
      },
      renderHeading() {
        const flat = TextHelper.declensionWords(this.result.flats, ['квартира', 'квартиры', 'квартир']);
        const build = TextHelper.declensionWords(this.result.builds, ['проверенной новостройке', 'проверенных новостройках', 'проверенных новостройках']);

        return !this.success
          ? `
            Предварительно для вас найдено <span class="poll-decor-badge">${this.result.flats}</span> ${flat}
            в <span class="poll-decor-badge">${this.result.builds}</span> ${build}.
            ${this.isMobile ? ' Оставьте номер телефона, мы отправим вам SMS со ссылкой на результаты.' : ' Оставьте, пожалуйста, контактные данные, чтобы мы смогли отправить персональное предложение.'}
          `
          : `
            ${this.isMobile ? 'Спасибо, вы получите результаты на указанный телефон совсем скоро! ' : 'Спасибо, вы получите результаты на указанную электронную почту совсем скоро! '}
            У вас есть возможность изменить параметры и сделать новый подбор.
          `;
      }
    },
    data: () => ({
      phoneRegex: /^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/,
      urlSender: '/quiz/api/send'
    }),
    async created() {
      if (!this.result) {
        await this.$store.dispatch('getTotalWithNovos');
      }
    },
    mounted() {
      //this.ya('quiz_request');
      this.triggerEvent();
    },
    methods: {
      checkForm(event) {
        this.$validator.validate().then(result => {
          if (result) {
            this.$store.dispatch('sendQuiz', this.urlSender);

            phoneModel.register(this.phone);
            phoneModel.send();
          }
        });
      },

      goHome() {
        this.$store.commit('clearMessage');
        this.$store.commit('clearForm');
        this.$store.commit('goHome');
      },

      setDefaultPhonePrefix(event) {
        if (event.target.value === '') {
          event.target.value = '+7';
        }
      },

      eventLastLetter(event) {
        this.phone = event.target.value;
        if (this.phone.match(this.phoneRegex)) {
          phoneModel.register(this.phone);
        }
      },

      eventBlurEmail() {
        phoneModel.params.email = this.email
      },

      triggerEvent() {
        this.ya('quiz_request')
      }
    }
  }
</script>
